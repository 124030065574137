/*eslint-disable*/
import React, { useState } from "react";
import { Link } from "react-router-dom";
import LoginModal from '../../views/auth/Login';
import RegisterModal from '../../views/auth/Register';
import { Redirect } from 'react-router-dom'
import { message } from "antd";

import  img_ttc  from '../Navbars/CustomNavbar/TTC-High-Res.jpg';

// import PagesDropdown from "components/Dropdowns/PagesDropdown.js";

export default function Navbar(props) {

  const [navbarOpen, setNavbarOpen] = useState(false);
  const [isLogin, setLogin] = useState(false);
  const [isRegister, setRegister] = useState(false);
  const [isRedirect, setRedirect] = useState(false);
  const [userToken, setUserToken] = useState(localStorage.getItem('token'))
  const [isLoading, setLoading] = useState(false);

  const closeModal = (operation) => {
    setLogin(false);
    setRegister(false);
    document.body.style.overflow = "auto";
    if (operation === 'redirect') {
      setRedirect(true);
    }
  }

  const openRegister = () => {
    setLogin(false);
    setRegister(true);
  }

  const handleLogin = () => {
    if (userToken) {
      localStorage.setItem('token', '');
      setUserToken('');
      message.info('Successfully logged out!!');
    } else {
      setLogin(true);
      setNavbarOpen(!navbarOpen);
    }
  }

  const loadingCircle = (boolValue) => {
    setLoading(boolValue);
  }

  if (isRedirect) {
    return (
      <Redirect to="/profile" />
    )
  }

  return (
    <div >
      <nav className="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg"
        style={{
          backgroundColor: props.transparent ? 'transparent' : '#38bdf8'
        }}
      >
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            {/* <Link
              className="text-white text-sm font-bold leading-relaxed inline-block mr-4 whitespace-nowrap uppercase"
              to="/"
            >
              <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                <img src={require("../../assets/img/logo.svg").default} />
              </div>
            </Link> */}

            {/* <Link
              className="text-white text-sm font-bold leading-relaxed inline-block mr-4 whitespace-nowrap uppercase"
              to="/"
            >
              Services
            </Link> */}
            
          </div>
          <div
            className={
              "lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none" +
              (navbarOpen ? " block rounded shadow-lg" : " hidden")
            }
            id="example-navbar-warning"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              {/* <li className="flex items-center">
                <a
                  className="lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  href="https://www.facebook.com/nohub.co"
                  target="_blank"
                >
                  <i className="lg:text-blueGray-200 text-blueGray-400 fab fa-facebook text-lg leading-lg " />
                  <span className="lg:hidden inline-block ml-2">Share</span>
                </a>
              </li>

              <li className="flex items-center">
                <a
                  className="lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  href="https://www.instagram.com/n0hub/"
                  target="_blank"
                >
                  <i className="lg:text-blueGray-200 text-blueGray-400 fab fa-instagram text-lg leading-lg " />
                  <span className="lg:hidden inline-block ml-2">Follow</span>
                </a>
              </li>

              <li className="flex items-center">
                <a
                  className="lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  href="https://www.linkedin.com/company/nohub"
                  target="_blank"
                >
                  <i className="lg:text-blueGray-200 text-blueGray-400 fab fa-linkedin text-lg leading-lg " />
                  <span className="lg:hidden inline-block ml-2">Connect</span>
                </a>
              </li> */}

              <li className="flex items-center">
                <button
                  className="bg-white text-blueGray-700 active:bg-blueGray-50 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    const el = document.getElementById('contact-us-form');
                    el && el.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                      inline: "start",
                    });
                  }}
                >
                  <i className="fas"></i> Contact Us
                </button>
                {/* <Link
                  to="/services"
                >
                  <button
                    className="bg-white text-blueGray-700 active:bg-blueGray-50 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                    type="button"
                  >
                    <i className="fas"></i> Services
                  </button>
                </Link> */}
                {/* <Link
                  to="/courses"
                >
                  <button
                    className="bg-white text-blueGray-700 active:bg-blueGray-50 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                    type="button"
                  >
                    <i className="fas"></i> Courses
                  </button>
                </Link> */}

                {/* <button
                  className="bg-white text-blueGray-700 active:bg-blueGray-50 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                  type="button"
                  onClick={handleLogin}
                >
                  <i className="fas"></i> {userToken ? 'Logout' : 'Login'}
                </button> */}
              </li>
            </ul>
          </div>
        </div>
        {isLogin ?
          <LoginModal
            closeModal={closeModal}
            openRegister={openRegister}
            loadingCircle={loadingCircle}
            isLoading={isLoading}
          /> : null}
        {isRegister ?
          <RegisterModal
            closeModal={closeModal}
            loadingCircle={loadingCircle}
            isLoading={isLoading}
          /> : null}
      </nav>
    </div>
  );
}
