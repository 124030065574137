import React, { useState, useEffect } from "react";
// components
import Navbar from "components/Navbars/AuthNavbar.js";

export default function TermsAndCondition() {

    const [courseEnroll, setCourseEnroll] = useState(false);

    return (
        <>
            <Navbar  />
            <iframe src="https://docs.google.com/document/d/e/2PACX-1vTV2xOf6ojdVjZx_H4ar9rSjXFeWiHKzZqq1cWvSd61SSv38VqGinOvFOZt0SHjJUC4Fm8a6xe4e7MY/pub?embedded=true"
                style={{
                    height: "100vh",
                    width: "100vw"
                }}
            ></iframe>
        </>
    );
}
