import React, { useState, useEffect } from "react";
// components
import Navbar from "components/Navbars/AuthNavbar.js";

export default function PrivacyPolicy() {

    const [courseEnroll, setCourseEnroll] = useState(false);

    return (
        <>
            <Navbar  />
            <iframe
                style={{
                    height: "100vh",
                    width: "100vw"
                }}
                src="https://docs.google.com/document/d/e/2PACX-1vTasdXd8C4eQk6QGnlF3cqsblwwiYqfrVAwlteBRfGSzn1Qb1O5OkUD5dw2STIkVHR7i_wvjQH_tYjC/pub?embedded=true"></iframe>
        </>
    );
}
