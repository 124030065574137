import React, { useState, useEffect } from "react";
import RegisterModal from './Register';
import { authLogin } from '../../redux/actions/authAction';
import { connect } from 'react-redux';
import { message, Spin } from 'antd';

function Login({ closeModal, openRegister, authLogin, authData, loadingCircle, isLoading }) {

  const [isRegister, setRegister] = useState(false);
  const [emailId, setEmailId] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmit, setSubmit] = useState(false);

  const closeRegister = () => {
    setRegister(false);
  }
  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, [])

  useEffect(() => {
    if(isSubmit && !Array.isArray(authData)){
      if (authData?.message) {
        if (!authData.success) {
          message.info(authData.message);
          return;
        } else {
          loadingCircle(false);
          closeModal('redirect');
          message.success("Login Successfully");
        }
      }
    }
  }, [authData, closeModal])

  const handleSubmit = async () => {
    loadingCircle(true);
    setSubmit(true);
    const payload = {
      emailId,
      password
    }
    await authLogin(payload);
  }

  return (
    <>
      {isRegister ? <RegisterModal closeRegister={closeRegister} /> :
        <div
          style={{
            backgroundColor: '#00000050',
            position: 'fixed',
            transform: 'translate(-50%, -50%)',
            top: '50%', left: '50%', width: '100%', height: '100%',
            zIndex: 101,
          }}
        >
          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                  <div className="rounded-t mb-0 px-4">
                    <button
                      style={{ float: 'right', fontSize: '24px' }}
                      onClick={() => closeModal(false)}
                    >
                      x
                    </button>
                  </div>
                  <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <div className="text-blueGray-400 text-center mb-3 font-bold">
                      <small>Sign in with credentials</small>
                    </div>
                    <form>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Email"
                          onChange={(event) => setEmailId(event.target.value)}
                        />
                      </div>

                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Password
                        </label>
                        <input
                          type="password"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Password"
                          onChange={(event) => setPassword(event.target.value)}
                        />
                      </div>
                      <div>
                        <label className="inline-flex items-center cursor-pointer">
                          <input
                            id="customCheckLogin"
                            type="checkbox"
                            className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                          />
                          <span className="ml-2 text-sm font-semibold text-blueGray-600">
                            Remember me
                          </span>
                        </label>
                      </div>
                      {isLoading && <Spin size="large" style={{display: 'flex', justifyContent: 'center'}}/>}
                      <div className="text-center mt-6">
                        <button
                          className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                          type="button"
                          onClick={handleSubmit}
                        >
                          Sign In
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="flex flex-wrap my-6 relative">
                    <div className="w-1/2 text-center">
                      <a
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      >
                        <span>Forgot password?</span>
                      </a>
                    </div>
                    <div className="w-1/2 text-right">
                      {/* <Link to="/auth/register" className="text-blueGray-200"> */}
                      <button className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        onClick={() => openRegister(true)}
                      >Create new account</button>
                      {/* </Link> */}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>}
    </>
  );
}

function mapStateToProps(state) {
  return {
    authData: state.login.authLogin?.data?.data
  };
}

export default connect(
  mapStateToProps,
  {
    authLogin
  }
)(Login);