import * as Types from '../actions/ActionTypes';
import { combineReducers } from 'redux';

function makeRequestReducer(actionType, initialState = {}) {
    const init = {
        data: [],
        error: false,
        isLoading: false,
        ...initialState
    };

    return (state = init, { type, payload }) => {
        switch (type) {
            case actionType:
                return {
                    ...state,
                    isLoading: true
                };
            case `${actionType}.success`:
                return {
                    ...state,
                    isLoading: false,
                    data: payload,
                    error: false
                };
            case `${actionType}.failed`:
                return {
                    ...state,
                    data: payload,
                    isLoading: false,
                    error: true
                };
            default:
                return state;
        }
    };
}

export default combineReducers({
    authLogin: makeRequestReducer(Types.AUTH_LOGIN),
    authRegister: makeRequestReducer(Types.AUTH_REGISTER)
});