import axios from 'axios';
import * as actionTypes from './ActionTypes';
import * as actionHandlers from './ActionHandler';

const BASE_URL = 'http://nohub-backend-dev.ap-south-1.elasticbeanstalk.com'

export const fetchProfile = () => (dispatch) => {
  const token = localStorage.getItem('token');
  dispatch(actionHandlers.tryHandle(actionTypes.GET_PROFILE));
  return axios.get(`${BASE_URL}/api/v1/user`, {
    headers: {
      'Authorization': token
    }
  })
    .then((response) => {
      dispatch(actionHandlers.handleResponse(
        actionTypes.GET_PROFILE_SUCCESS,
        response.data
      ))
    }).catch((error) => {
      dispatch(actionHandlers.handleError(actionTypes.GET_PROFILE_FAILED, error.message));
    });
};