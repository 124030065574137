import React, { useState, useEffect } from "react";
// components
import ChooseCard from "../../components/ChooseCard";
import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";
import CourseEnrollModal from 'components/Modal/CourseEnroll';

export default function Courses() {

    const [courseEnroll, setCourseEnroll] = useState(false);

    const [countryCode , setUserCountry] = React.useState('');

    useEffect(() => {
        fetch('http://ip-api.com/json').then((data) => {
            return data.json();
        }).then((result) => {
            console.log(result, "result")
            setUserCountry(result.countryCode)
        })
    }, [])

    return (
        <>
            {courseEnroll &&
                <CourseEnrollModal setCourseEnroll={setCourseEnroll} />}
            <Navbar transparent />
            <ChooseCard setCourseEnroll={setCourseEnroll} countryCode={countryCode}/>
            {/* <Footer /> */}
        </>
    );
}
