export const AUTH_LOGIN = 'authLogin';
export const AUTH_LOGIN_SUCCESS = `${AUTH_LOGIN}.success`;
export const AUTH_LOGIN_FAILED = `${AUTH_LOGIN}.failed`;

export const AUTH_REGISTER = 'authRegister';
export const AUTH_REGISTER_SUCCESS = `${AUTH_REGISTER}.success`;
export const AUTH_REGISTER_FAILED = `${AUTH_REGISTER}.failed`;

// -------------------------PROFILE TYPE---------------------------

export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILE_SUCCESS = `${GET_PROFILE}.success`;
export const GET_PROFILE_FAILED = `${GET_PROFILE}.failed`;

// --------------XXXX-------PROFILE TYPE ------XXXX----------------