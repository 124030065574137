import React, {useState, useEffect} from "react";

import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";
import EnrollCard from "./EnrollCard";
import styled from "styled-components/macro";
import tw from "twin.macro";
import { Tag } from 'antd';
// ---------------------------------------------------------------------------
import { fetchProfile } from '../../redux/actions/ProfileAction';
import { connect } from 'react-redux';
// ---------------------------------------------------------------------------



const Wrapper = tw.div`w-full min-h-screen lg:pl-16`;
const ProfileWrapper = styled.div`
  margin-top: -200px;
  @media screen and (max-width: 460px) {
    margin-top: -120px;
  }
`;
const ProfileSection = tw.div`flex flex-col lg:flex-row`;
const ProfileImg = styled.img`
  left: 0;
  top: 48px;
  @media screen and (max-width: 460px) {
    top: -76px;
  }
`;
const ToolSection = tw.div`absolute right-4 top-4 flex text-gray-700`;
const Bookmark = tw.button`border-0 rounded-full flex justify-center items-center bg-gray-50 p-2 w-10 h-10 shadow-md`;
const EditTool = tw.button`border-0 rounded-lg flex items-center px-4 bg-gray-50 ml-4 font-bold shadow-md h-10`;
const ProfileHeader = tw.div`flex flex-col mt-28 lg:mt-12 md:mx-48`;
const ProfileName = tw.span`font-bold text-xl leading-tight`;
const ProfileDesignation = tw.span`text-blue-600 text-sm`;
const ProfileDescription = tw.h6`py-4 lg:py-2 text-gray-600`;
const ProfileAddress = tw.div`flex flex-col lg:flex-row mt-3`;
const Location = tw.div`flex items-center py-1`;
const EmailAddress = tw.div`flex items-center py-1 lg:px-4`;
const ProfileTags = tw.div`flex flex-wrap`;
const CourseSection = tw.div`flex flex-col py-10 lg:pt-20`;
const CourseHeader = tw.div`flex text-lg font-bold`;
const CourseEnrolled = tw.div`py-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6`;

const tags = [
  'Java',
  'C#',
  'Git'
]

const Profile = (props) => {
  const [profile, setProfile] = useState({});

  useEffect(()=>{
    props?.fetchProfile();
  },[])

  useEffect(()=>{
    if(props?.profileData?.code === 200){
      setProfile(props?.profileData?.data)
    }
  },[props?.profileData])

  return (
    <>
      <Navbar transparent />
      <main className="profile-page">
        <section tw="h-60 lg:h-96" className="relative block">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                "url('https://images.unsplash.com/photo-1499336315816-097655dcfbda?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2710&q=80')",
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            ></span>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-200 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </section>
        <section className="relative py-16 bg-blueGray-200">
          <div className="container mx-auto px-4">
            <ProfileWrapper className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg">
              <div className="px-6">
                <Wrapper>
                  <ProfileSection>
                    <div className="relative">
                      <ProfileImg
                        alt="..."
                        src={require("assets/img/team-2-800x800.jpg").default}
                        className="shadow-xl rounded-full h-auto align-middle border-none absolute max-w-150-px"
                      />
                    </div>
                    <ToolSection>
                      {/* <Bookmark className="bookmark">
                        <i className="fas fa-bookmark"></i>
                      </Bookmark> */}
                      <EditTool className="edit">
                        <i className="fas fa-pen"></i>
                        &nbsp;&nbsp;Edit
                      </EditTool>
                    </ToolSection>
                    <ProfileHeader>
                      <ProfileName>{profile?.firstName || 'Not available'}</ProfileName>
                      <ProfileDesignation>{profile?.designation || ''}</ProfileDesignation>
                      <ProfileAddress>
                        <Location>
                          <i tw='text-gray-700' className="fas fa-map-marker-alt"></i>
                          <span tw='ml-3'>{profile?.address || 'Address not given'}</span>
                        </Location>
                        <EmailAddress>
                          <i tw='text-gray-700' className="fas fa-envelope"></i>
                          <span  tw='ml-3'>{profile?.emailId || 'Email does not exist'}</span>
                        </EmailAddress>
                        <EmailAddress>
                          <i tw='text-gray-700' className="fas fa-phone-alt"></i>
                          <span  tw='ml-3'>{profile?.primaryPhone || 'Phone number not given'}</span>
                        </EmailAddress>
                      </ProfileAddress>
                      <ProfileDescription>
                        Full Stack Developer with 6+ years of hands-on experience designing, 
                        developing, and implementing applications and solutions using a range 
                        of technologies and programming languages. Seeking to leverage broad 
                        development experience and hands-on technical expertise in a challenging
                        role as a Full-stack Developer.
                      </ProfileDescription>
                      <div tw='text-sm font-bold py-3'>SKILLS</div>
                      <ProfileTags>
                        {tags?.map((item, index) => 
                          <Tag key={index} tw='text-gray-800 bg-gray-100 shadow-sm rounded px-3'>{item}</Tag>
                        )}
                      </ProfileTags>
                    </ProfileHeader>
                  </ProfileSection>
                  <CourseSection>
                    <CourseHeader>
                      ENROLLED COURSES
                    </CourseHeader>
                    <CourseEnrolled>
                        <EnrollCard />
                        <EnrollCard />
                        <EnrollCard />
                        <EnrollCard />
                        <EnrollCard />
                    </CourseEnrolled>
                  </CourseSection>
                </Wrapper>
              </div>
            </ProfileWrapper>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}


function mapStateToProps(state) {
  return {
    profileData: state.profile.fetchProfile?.data?.data
  };
}

export default connect(
  mapStateToProps,
  {
    fetchProfile
  }
)(Profile);