import axios from 'axios';
import * as actionTypes from './ActionTypes';
import * as actionHandlers from './ActionHandler';

const BASE_URL = 'http://nohub-backend-dev.ap-south-1.elasticbeanstalk.com'

export const authLogin = payload => (dispatch) => {
  dispatch(actionHandlers.tryHandle(actionTypes.AUTH_LOGIN));
  return axios.post(`${BASE_URL}/api/v1/auth`, payload)
    .then((response) => {
      localStorage.setItem('token', response?.data?.data?.token?.token);
      localStorage.setItem('userId', response?.data?.data?.user?.userId);
      dispatch(actionHandlers.handleResponse(
        actionTypes.AUTH_LOGIN_SUCCESS,
        response.data
      ))
    }).catch((error) => {
      dispatch(actionHandlers.handleError(actionTypes.AUTH_LOGIN_FAILED, error.response.data));
    });
};

export const authRegister = payload => (dispatch) => {
  dispatch(actionHandlers.tryHandle(actionTypes.AUTH_REGISTER));
  return axios.post(`${BASE_URL}/api/v1/user`, payload)
    .then((response) => {
      dispatch(actionHandlers.handleResponse(
        actionTypes.AUTH_REGISTER_SUCCESS,
        response.data
      ))
    }).catch((error) => {
      dispatch(actionHandlers.handleError(actionTypes.AUTH_REGISTER_FAILED, error.response.data));
    });
};