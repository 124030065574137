import styled from "styled-components";

export const PanelTitle = styled.div`
    font-size: 18px;
    font-weight: 700;
    color: rgba(0,0,0,.87);
    padding: 10px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-around
`;