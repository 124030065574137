import React, { useState, useEffect } from "react";
// import { authLogin } from "../../redux/actions/authAction";
import { validateEmail } from "utils/StaticFunctions";
import { Spin } from "antd";
import tw from "twin.macro";

import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;

function Index({ setCourseEnroll, modalName }) {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      let courseModal = document.getElementById("courseModal");
      courseModal?.remove();
    };
  }, []);

  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = React.useState({
    name: null,
    email: null,
    phone: null,
    message: null,
  });
  const [formSubmitStatus, setFormSubmitStatus] = React.useState(false);
  const [errorState, setErrorState] = React.useState(false);

  useEffect(() => {
    if(modalName === 'counseling'){
      setFormState({
        ...formState,
        message: 'Want to book a Free Counseling session'
      })
    }
  },[modalName])

  const handleFormStateChange = (event) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    });
    setFormSubmitStatus(false);
    if (formState.name === null && formState.email === null) {
      setErrorState(false);
    } else {
      setErrorState(true);
    }
  };
  const submitContactUsForm = (e) => {
    setLoading(true);
    if (formState.name === null) {
      return;
    }
    if (formState.email === null) {
      return;
    }
    if (!validateEmail(formState.email)) {
      return;
    }
    console.log("print the error state", errorState);
    fetch("https://vegetable-backend.herokuapp.com/api/v1/service/contact-us", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formState),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setFormSubmitStatus(true);
        } else {
          setFormSubmitStatus(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        setFormSubmitStatus(false);
        setLoading(false);
        console.log(err);
      });
    document.body.style.overflow = "auto";
    e.preventDefault();
  };
  return (
    <div
      id="courseModal"
      tw="fixed -translate-y-1/2 -translate-x-1/2 top-0 left-0 w-full h-full"
      className="z-110 bg-black-50"
    >
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-4">
                <button
                  style={{ float: "right", fontSize: "24px" }}
                  tw="focus:outline-none"
                  onClick={() => {
                    setCourseEnroll(false);
                    document.body.style.overflow = "auto";
                  }}
                >
                  x
                </button>
              </div>
              {!formSubmitStatus ? (
                <Spin style={{marginTop:'12px'}} spinning={loading} indicator={antIcon}>
                  <form
                    className="flex-auto p-5 lg:p-10"
                    onSubmit={submitContactUsForm}
                    id="contact-us-form"
                  >
                    <h4 className="text-2xl font-semibold">Join to Enroll!</h4>
                    <p className="leading-relaxed mt-1 mb-4 text-blueGray-500">
                      {/* Learning to Code with NoHub */}
                    </p>
                    <div className="relative w-full mb-3 mt-8">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="full-name"
                      >
                        Full Name*
                      </label>
                      <input
                        required
                        name="name"
                        onChange={handleFormStateChange}
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Full Name"
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="email"
                      >
                        Email*
                      </label>
                      <input
                        required
                        name="email"
                        onChange={handleFormStateChange}
                        type="email"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Email"
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="number"
                      >
                        Mobile Number
                      </label>
                      <input
                        name="phone"
                        onChange={handleFormStateChange}
                        type="number"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Mobile Number"
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="message"
                      >
                        Message
                      </label>
                      <textarea
                        value={formState.message}
                        name="message"
                        onChange={handleFormStateChange}
                        rows="4"
                        cols="80"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Enter your query..."
                      />
                    </div>
                    <div className="text-center mt-6">
                      <button
                        style={{ backgroundColor: "gray" }}
                        className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="submit"
                        onClick={submitContactUsForm}
                        disabled={false}
                        tw="focus:outline-none"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </Spin>
              ) : (
                <div className="text-center mt-6 mb-6">
                  <h3 tw="font-bold text-gray-800 text-2xl">
                    Thank you for showing interest
                  </h3>
                  <p tw="font-bold text-gray-600 text-lg">
                    {" "}
                    We will contact you soon!
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
