import React, { useState } from "react";
import { ButtonContainer, PanelTitle } from './style';
import { Collapse } from 'antd';
import { frontEndCourse, backEndCourse } from '../../assets/constant/courseData';

const { Panel } = Collapse;

const ChooseCard = ({ setCourseEnroll, countryCode }) => {

    const [courseName, setCourseName] = useState('')

    const handleDetails = (fieldName) => {
        if (fieldName === 'frontEndCourse') {
            setCourseName(frontEndCourse)
        } else if (fieldName === 'backEndCourse') {
            setCourseName(backEndCourse);
        }
        console.log("handleDetails", fieldName);
        document?.getElementById('courseDetails')?.scrollIntoView({
            behavior: 'smooth',
        })
    }

    return (
        <>
            <div className="lm-tabel cards-bg-price">
                <div className="lm-item lm-item-2">
                    <div className="lm-item-top"><span className="lm-item-title lm-underline">Front End Development</span>
                        <div className="lm-item-price"> {countryCode == "IN" ? "₹ 5,000" : "$ 300"} <span className="line-through lm-item-price-small">{countryCode == "IN" ? "₹ 10,000" : "$ 600"}</span></div>
                    </div>
                    <div className="lm-item-body">
                        <div className="lm-item-desc"><strong>Front End Development</strong>
                            <p>HTML, React, CSS</p>
                        </div>
                        <p>MODULES COVERED</p>
                        <br />
                        <ul className="lm-item-list">
                            <li>#1 React<i className="icon-ok"></i></li>
                            <li>#2 HTML<i className="icon-ok"></i></li>
                            <li>#3 CSS<i className="icon-ok"></i></li>
                            <li>#4 GIT<i className="icon-ok"></i></li>
                            <li>#5 Node<i className="icon-cancel"></i></li>
                            <li>#6 Mongo<i className="icon-cancel"></i></li>
                            <li>#7 Express<i className="icon-cancel"></i></li>
                        </ul>
                        <ButtonContainer>
                            <div className="lm-item-link">
                                <a href="#" onClick={() => setCourseEnroll(true)}>
                                    <svg width="152" height="52" xmlns="http://www.w3.org/2000/svg">
                                        <defs>
                                            <linearGradient id="lm-gr-2" x1="0" y1="0" x2="100%" y2="100%">
                                                <stop offset="0%" stop-color="#7E052D"></stop>
                                                <stop offset="100%" stop-color="#F6266B"></stop>
                                            </linearGradient>
                                        </defs>
                                        <rect x="1" y="1" width="150" height="50" rx="25" fill="#fff" stroke="url(#lm-gr-2)" stroke-width="1"></rect>
                                        <text x="33%" y="65%" fill="url(&quot;#lm-gr-2&quot;)">Enroll</text>
                                    </svg>
                                </a>
                            </div>
                            <div className="lm-item-link">
                                <button onClick={() => handleDetails('frontEndCourse')}>
                                    <svg width="152" height="52" xmlns="http://www.w3.org/2000/svg">
                                        <defs>
                                            <linearGradient id="lm-gr-2" x1="0" y1="0" x2="100%" y2="100%">
                                                <stop offset="0%" stop-color="#7E052D"></stop>
                                                <stop offset="100%" stop-color="#F6266B"></stop>
                                            </linearGradient>
                                        </defs>
                                        <rect x="1" y="1" width="150" height="50" rx="25" fill="#fff" stroke="url(#lm-gr-2)" stroke-width="1"></rect>
                                        <text x="33%" y="65%" fill="url(&quot;#lm-gr-2&quot;)">Details</text>
                                    </svg>
                                </button>
                            </div>
                        </ButtonContainer>
                    </div>
                    <div className="lm-item-bottom"></div>
                </div>
                <div className="lm-item lm-item-1">

                    <div className="lm-item-top"><span className="lm-item-title lm-underline">Full Stack Development</span>
                        <div className="lm-item-price"> {countryCode == "IN" ? "₹ 8,000" : "$ 300"}<span className="line-through lm-item-price-small">{countryCode == "IN" ? "₹ 15,000" : "$ 600"}</span></div>
                    </div>
                    <div className="lm-item-body">
                        <div className="lm-item-desc"><strong>Full STACK DEVELOPMENT</strong>
                            <p>React, Node, Express, Mongo</p>
                        </div>
                        <p>MODULES COVERED</p>
                        <br />
                        <ul className="lm-item-list">
                            <li>#1 Node<i className="icon-ok"></i></li>
                            <li>#2 Mongo<i className="icon-ok"></i></li>
                            <li>#3 Express<i className="icon-ok"></i></li>
                            <li>#4 GIT<i className="icon-ok"></i></li>
                            <li>#5 React<i className="icon-ok"></i></li>
                            <li>#6 HTML<i className="icon-ok"></i></li>
                            <li>#7 CSS<i className="icon-ok"></i></li>
                        </ul>

                        <ButtonContainer>
                            <div className="lm-item-link">
                                <a href="#" onClick={() => setCourseEnroll(true)}>
                                    <svg width="152" height="52" xmlns="http://www.w3.org/2000/svg">
                                        <defs>
                                            <linearGradient id="lm-gr-1" x1="0" y1="0" x2="100%" y2="100%">
                                                <stop offset="0%" stop-color="#241326"></stop>
                                                <stop offset="100%" stop-color="#85468C"></stop>
                                            </linearGradient>
                                        </defs>
                                        <rect x="1" y="1" width="150" height="50" rx="25" fill="#fff" stroke="url(#lm-gr-1)" stroke-width="1"></rect>
                                        <text x="33%" y="65%" fill="url(&quot;#lm-gr-1&quot;)">Enroll</text>
                                    </svg>
                                </a>
                            </div>
                            <div className="lm-item-link">
                                <button onClick={() => handleDetails('fullStackCourse')}>
                                    <svg width="152" height="52" xmlns="http://www.w3.org/2000/svg">
                                        <defs>
                                            <linearGradient id="lm-gr-1" x1="0" y1="0" x2="100%" y2="100%">
                                                <stop offset="0%" stop-color="#241326"></stop>
                                                <stop offset="100%" stop-color="#85468C"></stop>
                                            </linearGradient>
                                        </defs>
                                        <rect x="1" y="1" width="150" height="50" rx="25" fill="#fff" stroke="url(#lm-gr-1)" stroke-width="1"></rect>
                                        <text x="33%" y="65%" fill="url(&quot;#lm-gr-1&quot;)">Details</text>
                                    </svg>
                                </button>
                            </div>
                        </ButtonContainer>
                    </div>


                    <div className="lm-item-bottom"><span className="lm-underline">Enroll now </span>and we meet tomorrow!</div>
                </div>
                <div className="lm-item lm-item-3">

                    <div className="lm-item-top"><span className="lm-item-title lm-underline">Back End Development</span>
                        <div className="lm-item-price">{countryCode == "IN" ? "₹ 5,000" : "$ 300"}<span className="line-through lm-item-price-small">{countryCode == "IN" ? "₹ 10,000" : "$ 600"}</span></div>
                    </div>
                    <div className="lm-item-body">
                        <div className="lm-item-desc"><strong>Back End Development</strong>
                            <p>Node, Express, Mongo</p>
                        </div>
                        <p>MODULES COVERED</p>
                        <br />
                        <ul className="lm-item-list">
                            <li>#1 Node<i className="icon-ok"></i></li>
                            <li>#2 Mongo<i className="icon-ok"></i></li>
                            <li>#3 Express<i className="icon-ok"></i></li>
                            <li>#4 GIT<i className="icon-ok"></i></li>
                            <li>#5 React<i className="icon-cancel"></i></li>
                            <li>#6 HTML<i className="icon-cancel"></i></li>
                            <li>#7 CSS<i className="icon-cancel"></i></li>
                        </ul>
                        <ButtonContainer>
                            <div className="lm-item-link">
                                <a href="#" onClick={() => setCourseEnroll(true)}>
                                    <svg width="152" height="52" xmlns="http://www.w3.org/2000/svg">
                                        <defs>
                                            <linearGradient id="lm-gr" x1="0" y1="0" x2="100%" y2="100%">
                                                <stop offset="0%" stop-color="#1A466F"></stop>
                                                <stop offset="100%" stop-color="#4D93D5"></stop>
                                            </linearGradient>
                                        </defs>
                                        <rect x="1" y="1" width="150" height="50" rx="25" fill="#fff" stroke="url(#lm-gr)" stroke-width="1"></rect>
                                        <text x="33%" y="65%" fill="url(&quot;#lm-gr&quot;)">Enroll</text>

                                    </svg>
                                </a>
                            </div>
                            <div className="lm-item-link">
                                <button
                                    onClick={() => handleDetails('backEndCourse')}
                                >
                                    <svg width="152" height="52" xmlns="http://www.w3.org/2000/svg">
                                        <defs>
                                            <linearGradient id="lm-gr" x1="0" y1="0" x2="100%" y2="100%">
                                                <stop offset="0%" stop-color="#1A466F"></stop>
                                                <stop offset="100%" stop-color="#4D93D5"></stop>
                                            </linearGradient>
                                        </defs>
                                        <rect x="1" y="1" width="150" height="50" rx="25" fill="#fff" stroke="url(#lm-gr)" stroke-width="1"></rect>
                                        <text x="33%" y="65%" fill="url(&quot;#lm-gr&quot;)">Details</text>

                                    </svg>
                                </button>
                            </div>
                        </ButtonContainer>
                    </div>
                    <div className="lm-item-bottom"></div>
                </div>
            </div>
            <div
            //   className="cards-bg-price"
            >
                {/* {courseName &&
                    <div
                        id="courseDetails"
                        style={{
                            margin: '0% 20% 10% 20%',
                            paddingBottom: '5%',
                            marginTop: 20,
                            boxShadow: 'rgb(83 90 122 / 54%) 3px 4px 12px -1px',
                            borderRadius: 20,
                            overflow: 'hidden'
                        }}
                    >
                        <Collapse expandIconPosition="right" >
                            {courseName.map((item, index) => (
                                <Panel header={<PanelTitle>{item}</PanelTitle>} key={index}>
                                </Panel>
                            ))}
                        </Collapse>
                    </div>} */}
            </div>
        </>
    )
}

export default ChooseCard;