import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from 'react-redux';
import Store from './redux/store';

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import "assets/styles/priceCards.css";
import 'antd/dist/antd.css'; 
// layouts

// views without layouts

import Landing from "views/Landing.js";
import Services from "views/Services.js";
import Profile from "views/Profile/Profile.js";
import Courses from "views/Courses/Courses.js";

import PrivacyPolicy from "views/PrivacyPolicy/PrivacyPolicy.js";
import TermsAndCondition from "views/TermsAndCondition/TermsAndCondition.js";


ReactDOM.render(
  <Provider store={Store}>
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Landing} />
        <Route path="/services" exact component={Services} />
        <Route path="/profile" exact component={Profile} />
        <Route path="/courses" exact component={Courses} />
        <Route path="/privacy-policy" exact component={PrivacyPolicy} />
        <Route path="/terms-and-conditions" exact component={TermsAndCondition} />
        
        <Redirect from="*" to="/" />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
