export const frontEndCourse = [
    'Topics' ,
    'Basics of HTML',
    'Basics of Css / HTML',
    'Basics of JS Hello world with JS,  Var , let , conts All Loops, Console.log',
    'Function Calling, Basics of Object and Arrays Variable Declaration , initialization', 
    'Basics of JS', // Closure , Function Calls, Copy , Function Currying
    'Basics of JS', // Promises , Async - await, call Back,
    'Introduction To React / React Virtual Dom / Basic of Web pack',
    'Class Bases Components Basics (Life Cycle Methods, set State and all Related to class based component)',
    // React Hooks Introduction and introduction of functional Components
    // React Hooks More Explained
    // Props and State Explanation Parent Component Child Component
    // React Router Introduction
    // Higher Order Component / Context API
    // React Redux Intro
    // React Redux (Store , Action , Reducer, Middlewares)
    // API calls (Fetch, Axios)
    // Introduction to servers, Basic Server Setup
    // Learning Nodejs, NPM, node modules
    // Setting up Express.js server, Middleware
    // Routes, Controllers, Services, Forms and HTTP requests
    'Brief Representational State Transfer (REST) APIs',
    'Intro to db, Basics of MongoDB, mongoose',
    'Mini Project (TODO list, contact list)',
    'Authentication -  Cookies, Sigin-in and sign-out ( Passport.js)',
    'User Authentication with Passport and JSON Web Token',
    'APIs, Social Authentication.',
    'Session, cookies, emails, file upload and download, pagination',
    'Socket.io, graphql?',
    'Major Project',
    'Databases',
   ' Architecture Design and Software Structure',
    'Setting up different environments for production',
    'Deployment on AWS  ',
]

export const backEndCourse = [
    'Javascript Fundamentals',
    'Setting up Git, basic git commands and much more.',
    'Setting up tools',
    'Introduction to servers, Basic Server Setup',
    'Learning Nodejs, NPM, node modules',
    'Setting up Express.js server, Middleware',
    'Routes, Controllers, Services, Forms and HTTP requests',
    'Brief Representational State Transfer (REST) APIs',
    'Intro to db, Basics of MongoDB, mongoose',
    'Callback hell and Promises',
    'Mini Project (TODO list, contact list)',
    'Authentication -  Cookies, Sigin-in and sign-out ( Passport.js)',
    'User Authentication with Passport and JSON Web Token',
    'APIs, Social Authentication.',
    'Session, cookies, emails, file upload and download, pagination',
    'Socket.io, graphql?',
    'Major Project',
    'Databases',
   ' Architecture Design and Software Structure',
    'Setting up different environments for production',
    'Deployment on AWS  ',
]