import React from "react";

import styled from "styled-components/macro";
import { Line } from "rc-progress";
import tw from "twin.macro";

const Card = styled.div`
  ${tw`flex flex-col justify-between bg-white rounded-lg shadow-lg overflow-hidden my-3`}
  // background-color: #c7d3ff;
  min-height: 250px;
  box-shadow: inset 0px 0px 0px 1px #eee;
`;
const CardHeader = tw.div`flex flex-col`;
const CardHeadWrapper = tw.div`flex justify-between px-3 py-2`;
const CardTitle = tw.div`flex font-bold text-lg items-center`;
const CardDesc = tw.div`flex text-sm text-gray-700 px-3 py-2 pt-0 pr-16`;
const CardIcon = tw.div`flex rounded-full w-10 h-10 justify-center items-center bg-indigo-600`;
const CardBody = tw.div`flex flex-col`;
const CardVenue = tw.div`flex px-3 py-2`;
const CardFooter = tw.div`flex flex-col`;
const CardProgress = tw.div`flex mt-2`;
const CardAction = tw.div`flex justify-end bg-gray-200 h-12 items-center`;
const CardButton = tw.button`flex justify-center items-center px-4 h-8 text-white border-0 focus:outline-none rounded-md bg-gray-700 mr-2`;

const tags = ["Java", "C#", "Git"];

export default function EnrollCard() {
  return (
    <>
      <Card>
        <CardHeader>
          <CardHeadWrapper>
            <CardTitle>Frontend Development Course</CardTitle>
            <CardIcon>
              <span>
                <i tw="text-white" className="fas fa-atlas"></i>
              </span>
            </CardIcon>
          </CardHeadWrapper>
          <CardDesc>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s
          </CardDesc>
        </CardHeader>
        <CardBody>
          <CardVenue>
            <span>
              <i tw="text-gray-700" className="fas fa-atlas"></i>
              <span tw="ml-2">
                <strong>3/4</strong> chapter
              </span>
            </span>
            <span tw="ml-3">
              <i tw="text-gray-700" className="fas fa-clock"></i>
              <span tw="ml-2">
                <strong>12 days</strong> remaining
              </span>
            </span>
          </CardVenue>
          <CardFooter>
            <CardProgress>
              <Line
                percent="60"
                strokeWidth="1"
                trailWidth="1"
                strokeColor="#7548ee"
              />
            </CardProgress>
            <CardAction>
              <CardButton>Continue</CardButton>
            </CardAction>
          </CardFooter>
        </CardBody>
      </Card>
    </>
  );
}
